
import { useEffect, useState, MouseEvent } from "react";

import "./UserCheckboxes.css";
import { useSelector } from "react-redux";

const UserCheckboxes = ({
	checkboxes,
	checkedItems,
	path,
	handleActionInput,
}) => {

	const botOptions = useSelector((state) => state.botOptions);
	const paths = useSelector((state) => state.paths);
	const [checkedBoxes, setCheckedBoxes] = useState();
	const [disabled, setDisabled] = useState(false);

	const botCheckboxRowStyle = {
		cursor: disabled ? `url(${botOptions.theme?.actionDisabledIcon}), auto` : "pointer",
		color: botOptions.theme?.primaryColor,
		borderColor: botOptions.theme?.primaryColor,
		...botOptions.botCheckboxRowStyle
	};

	const botCheckboxNextStyle = {
		cursor: disabled || checkedBoxes?.size < (checkboxes.min) 
			? `url(${botOptions.theme?.actionDisabledIcon}), auto` : "pointer",
		color: botOptions.theme?.primaryColor,
		borderColor: botOptions.theme?.primaryColor,
		...botOptions.botCheckboxNextStyle
	};

	const botCheckMarkStyle = {
		cursor: disabled ? `url(${botOptions.theme?.actionDisabledIcon}), auto` : "pointer",
		color: "transparent",
		...botOptions.botCheckMarkStyle
	};

	const botCheckMarkSelectedStyle = {
		cursor: disabled ? `url(${botOptions.theme?.actionDisabledIcon}), auto` : "pointer",
		color: "#fff",
		borderColor: botOptions.theme?.primaryColor,
		backgroundColor: botOptions.theme?.primaryColor,
		...botOptions.botCheckMarkSelectedStyle
	};

	useEffect(() => {
		if (paths.length > 0 && paths[paths.length - 1] !== path) {
			setDisabled(true);
		}
	}, [paths]);
	
	const handleCheckItems = (label) => {
		if (disabled) {
			return;
		}

		setCheckedBoxes((prevCheckedBoxes) => {
			const updatedCheckboxes = new Set(prevCheckedBoxes);
			if (updatedCheckboxes?.has(label)) {
				checkedItems.delete(label);
				updatedCheckboxes.delete(label);
			} else {
				if (checkedBoxes?.size == checkboxes.max) {
					return prevCheckedBoxes;
				}
				checkedItems.add(label);
				updatedCheckboxes.add(label);
			}
			return updatedCheckboxes;
		});
	};

	return (
		<div className={`botai-checkbox-container ${botOptions.botBubble?.showAvatar ? "botai-checkbox-offset" : ""}`}>
			{checkboxes.items.map((key) => {
		
				return (
					<div
						onMouseDown={(event) => {
							event.preventDefault();
							handleCheckItems(key)
						}}
						style={botCheckboxRowStyle}
						key={key}
						className="botai-checkbox-row-container"
					>
						<div className="botai-checkbox-row">
							<div
								style={checkedBoxes?.has(key) ? botCheckMarkSelectedStyle : botCheckMarkStyle}
								className="botai-checkbox-mark"
							/>
							<div className="botai-checkbox-label">{key}</div>
						</div>
					</div>
				);
			})}
			<button
				style={botCheckboxNextStyle}
				className="botai-checkbox-next-button"
				disabled={disabled || checkedBoxes?.size < (checkboxes.min)}
				onMouseDown={(event) => {
					event.preventDefault();
					const userInput = Array.from(checkedItems).join(", ");
					setDisabled(true);
					handleActionInput(path, userInput, botOptions.chatInput?.sendCheckboxOutput);
				}}
			>
			</button>
		</div>
	);
};

export default UserCheckboxes;