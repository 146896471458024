import React, { useState, useEffect, useRef } from 'react';
import SendButton from './SendButton/SendButton';
import VoiceButton from './VoiceButton/VoiceButton';
import { isDesktop } from '../../services/Utils';
import './ChatBotInput.css';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage } from '../../redux/slices/messagesSlice';

const ChatBotInput = ({
    inputRef,
    textAreaDisabled,
    textAreaSensitiveMode,
    voiceToggledOn,
    getCurrPath,
    handleToggleVoice,
    handleActionInput,
    hasFlowStarted,
    setHasFlowStarted
}) => {
    const botOptions = useSelector((state) => state.botOptions);
    const [isFocused, setIsFocused] = useState(false);
    const [inputLength, setInputLength] = useState(0);
    const dispatch = useDispatch();
    const [voiceInputTrigger, setVoiceInputTrigger] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    useEffect(() => {
        const currPath = getCurrPath();
        if (!currPath) {
            return;
        }
        handleActionInput(currPath, inputRef.current?.value);
        setInputLength(0);
    }, [voiceInputTrigger]);

    const handleFocus = () => {
        if (textAreaDisabled) {
            return;
        }
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (event.shiftKey) {
                if (!botOptions.chatInput?.allowNewline) {
                    event.preventDefault();
                }
                return;
            }
            handleSubmit(event);
        }
    };

    const handleTextareaValueChange = (event) => {
        if (textAreaDisabled && inputRef.current) {
            inputRef.current.value = '';
            return;
        }

        if (inputRef.current) {
            const characterLimit = botOptions.chatInput?.characterLimit;
            const allowNewline = botOptions.chatInput?.allowNewline;
            const newInput = allowNewline ? event.target.value : event.target.value.replace(/\n/g, ' ');
            if (characterLimit != null && characterLimit >= 0 && newInput.length > characterLimit) {
                inputRef.current.value = newInput.slice(0, characterLimit);
            } else {
                inputRef.current.value = newInput;
            }
            setInputLength(inputRef.current.value.length);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const currPath = getCurrPath();
        if (!currPath) {
            return;
        }
        handleActionInput(currPath, inputRef.current?.value);
        setInputLength(0);
    };

    const triggerSendVoiceInput = () => {
        setVoiceInputTrigger(prev => !prev);
    };

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.start();
                mediaRecorderRef.current.ondataavailable = (event) => {
                    audioChunksRef.current.push(event.data);
                };
                mediaRecorderRef.current.onstop = () => {
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                    setAudioBlob(audioBlob);
                    audioChunksRef.current = [];
                };
                setIsRecording(true);
            })
            .catch(error => console.error('Error accessing audio devices:', error));
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const handleAudioSend = () => {
        if (audioBlob) {
            const audioUrl = URL.createObjectURL(audioBlob);
            dispatch(addMessage({ type: 'audio', sender: 'user', content: audioUrl }));
            setAudioBlob(null);
        }
    };

    const textAreaStyle = {
        boxSizing: isDesktop ? 'content-box' : 'border-box',
        ...botOptions.chatInputAreaStyle,
    };

    const textAreaFocusedStyle = {
        outline: !textAreaDisabled ? 'none' : '',
        boxShadow: !textAreaDisabled ? `0 0 5px ${botOptions.theme?.primaryColor}` : '',
        boxSizing: isDesktop ? 'content-box' : 'border-box',
        ...botOptions.chatInputAreaStyle,
        ...botOptions.chatInputAreaFocusedStyle,
    };

    const textAreaDisabledStyle = {
        cursor: `url(${botOptions.theme?.actionDisabledIcon}), auto`,
        caretColor: 'transparent',
        boxSizing: isDesktop ? 'content-box' : 'border-box',
        ...botOptions.chatInputAreaStyle,
        ...botOptions.chatInputAreaDisabledStyle,
    };

    const characterLimitStyle = {
        color: '#989898',
        ...botOptions.characterLimitStyle,
    };

    const characterLimitReachedStyle = {
        color: '#ff0000',
        ...botOptions.characterLimitReachedStyle,
    };

    const placeholder = textAreaDisabled
        ? botOptions.chatInput?.disabledPlaceholderText
        : botOptions.chatInput?.enabledPlaceholderText;

    return (
        <div
            onMouseDown={(event) => {
                event.stopPropagation();
                if (!hasFlowStarted && botOptions.theme?.flowStartTrigger === 'ON_CHATBOT_INTERACT') {
                    setHasFlowStarted(true);
                }
            }}
            style={botOptions.chatInputContainerStyle}
            className="botai-chat-input-container"
        >
            {textAreaSensitiveMode && botOptions.sensitiveInput?.maskInTextArea ? (
                <input
                    ref={inputRef}
                    type="password"
                    className="botai-chat-input-textarea"
                    style={textAreaDisabled
                        ? textAreaDisabledStyle
                        : (isFocused ? textAreaFocusedStyle : textAreaStyle)}
                    placeholder={placeholder}
                    onChange={handleTextareaValueChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            ) : (
                <textarea
                    ref={inputRef}
                    className="botai-chat-input-textarea"
                    style={textAreaDisabled
                        ? textAreaDisabledStyle
                        : textAreaStyle}
                    rows={1}
                    placeholder={placeholder}
                    onChange={handleTextareaValueChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            )}
            <div className="botai-chat-input-button-container">
                {!botOptions.voice?.disabled && (
                    <VoiceButton
                        inputRef={inputRef}
                        textAreaDisabled={textAreaDisabled}
                        voiceToggledOn={voiceToggledOn}
                        handleToggleVoice={handleToggleVoice}
                        triggerSendVoiceInput={triggerSendVoiceInput}
                        setInputLength={setInputLength}
                        startRecording={startRecording}
                        stopRecording={stopRecording}
                        isRecording={isRecording}
                    />
                )}
                {audioBlob && (
                    <button onClick={handleAudioSend}>Send Audio</button>
                )}
                <SendButton handleSubmit={handleSubmit} />
                {botOptions.chatInput?.showCharacterCount &&
                    botOptions.chatInput?.characterLimit != null &&
                    botOptions.chatInput?.characterLimit > 0 && (
                        <div
                            className="botai-chat-input-char-counter"
                            style={
                                inputLength >= botOptions.chatInput?.characterLimit
                                    ? characterLimitReachedStyle
                                    : characterLimitStyle
                            }
                        >
                            {inputLength}/{botOptions.chatInput?.characterLimit}
                        </div>
                    )}
            </div>
        </div>
    );
};

export default ChatBotInput;
