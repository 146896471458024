

import UserOptions from "../../components/UserOptions/UserOptions";

export const processOptions = (block, path,
	injectMessage,
	handleActionInput) => {

	const options = block.options;
	if (!options) {
		return;
	}

	const content = <UserOptions options={options} path={path} handleActionInput={handleActionInput} />
	injectMessage(content);
}
