
import { useEffect, useState, MouseEvent } from "react";

import "./UserOptions.css";
import { useSelector } from "react-redux";

const UserOptions= ({
	options,
	path,
	handleActionInput
}) => {

	const botOptions = useSelector((state) => state.botOptions);

	const paths = useSelector((state) => state.paths);

	const [hoveredElements, setHoveredElements] = useState([]);

	const [disabled, setDisabled] = useState(false);

	const botOptionStyle = {
		cursor: disabled ? `url(${botOptions.theme?.actionDisabledIcon}), auto` : "pointer",
		color: botOptions.theme?.primaryColor,
		borderColor: botOptions.theme?.primaryColor,
		backgroundColor: "#fff",
		...botOptions.botOptionStyle
	};

	const botOptionHoveredStyle = {
		color: "#fff" ,
		borderColor: botOptions.theme?.primaryColor,
		backgroundColor: botOptions.theme?.primaryColor,
		...botOptions.botOptionHoveredStyle
	};

	useEffect(() => {
		if (paths.length > 0 && paths[paths.length - 1] !== path) {
			setDisabled(true);
		}
	}, [paths]);

	const handleMouseEnter = (index) => {
		setHoveredElements((prevHoveredElements) => {
			const newHoveredElements = [...prevHoveredElements];
			newHoveredElements[index] = true;
			return newHoveredElements;
		});
	};

	const handleMouseLeave = (index) => {
		setHoveredElements((prevHoveredElements) => {
			const newHoveredElements = [...prevHoveredElements];
			newHoveredElements[index] = false;
			return newHoveredElements;
		});
	};

	return (
		<div className={`botai-options-container ${botOptions.botBubble?.showAvatar ? "botai-options-offset" : ""}`}>
			{options.map((key, index) => {
				const isHovered = hoveredElements[index] && !disabled;
		
				return (
					<div
						key={key}
						className="botai-options"
						style={isHovered ? botOptionHoveredStyle : botOptionStyle}
						onMouseEnter={() => handleMouseEnter(index)}
						onMouseLeave={() => handleMouseLeave(index)}
						onMouseDown={(event) => {
							event.preventDefault();
							if (disabled) {
								return;
							}

							setDisabled(true);
							handleActionInput(path, key, botOptions.chatInput?.sendOptionOutput);
						}}
					>
						{key}
					</div>
				);
			})}
		</div>
	);
};

export default UserOptions;