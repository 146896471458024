
export const processMessage = async (block, params) => {

	console.log(block, params);

	const replyMessage = block.message;
	if (!replyMessage) {
		return;
	}
	
	if (typeof replyMessage === "string") {
		if (replyMessage.trim() !== "") {
			await params.injectMessage(replyMessage);
		}
		return;
	}

	let parsedMessage = replyMessage(params);
	console.log('parsed mes', parsedMessage)
	if (parsedMessage instanceof Promise) {
		parsedMessage = await parsedMessage;
	}

	if (!parsedMessage) {
		return;
	}

	// Check if parsedMessage is a string before calling trim
	if (typeof parsedMessage === "string") {
		if (parsedMessage.trim() !== "") {
			await params.injectMessage(parsedMessage);
		}
	} else {
		await params.injectMessage(parsedMessage);
	}
}
