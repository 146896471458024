import { Dispatch, SetStateAction } from "react";

import { processCheckboxes } from "./CheckboxProcessor";
import { processFunction } from "./FunctionProcessor";
import { processMessage } from "./MessageProcessor";
import { processOptions } from "./OptionProcessor";
import { processPath } from "./PathProcessor";
import { processRender } from "./RenderProcessor";
import { processTransition } from "./TransitionProcessor";

export const preProcessBlock = async (flow, path, params,
	setTextAreaDisabled, setTextAreaSensitiveMode,
	dispatch, setTimeoutId, 
	handleActionInput) => {

	const block = flow[path];

	if (!block) {
		throw new Error("Block is not valid.");
	}

	for (const attribute of Object.keys(block)) {
		const attributeAsFlowKeyType = attribute;
		switch (attributeAsFlowKeyType) {
		case "message":
			await processMessage(block, params);
			break;
		
		case "options":
			processOptions(block, path, params.injectMessage, handleActionInput);
			break;
		
		case "checkboxes":
			processCheckboxes(block, path, params.injectMessage, handleActionInput);
			break;
		
		case "render":
			await processRender(block, params);
			break;
		
		case "chatDisabled":
			if (block.chatDisabled) {
				setTextAreaDisabled(block.chatDisabled);
			}
			break;

		case "isSensitive":
			if (block.isSensitive) {
				setTextAreaSensitiveMode(block.isSensitive);
			}
			break;

		case "transition":
			await processTransition(flow, path, params, dispatch, setTimeoutId);
		}
	}
}

export const postProcessBlock = async (flow, path, params,
	setPaths) => {

	const block = flow[path];

	if (!block) {
		throw new Error("Block is not valid.");
	}

	const attributes = Object.keys(block);
	for (const attribute of attributes) {
		if (attribute === "function") {
			await processFunction(block, params);
		}
	}

	// path is always executed last in post-processing
	if (attributes.includes("path")) {
		return await processPath(block, params, setPaths);
	}
	return false;
}