// store.js
import { configureStore } from '@reduxjs/toolkit';
import botOptionsReducer from './slices/botOptionsSlice';
import messagesReducer from './slices/messagesSlice';
import pathsReducer from './slices/pathsSlice';

const store = configureStore({
    reducer: {
        botOptions: botOptionsReducer,
        messages: messagesReducer,
        paths: pathsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
