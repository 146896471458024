// slices/messagesSlice.js
import { createSlice } from '@reduxjs/toolkit';

const messagesSlice = createSlice({
  name: 'messages',
  initialState: [],
  reducers: {
    setMessages: (state, action) => action.payload,
    addMessage: (state, action) => {
      state.push(action.payload);
    },
    updateMessageContent: (state, action) => {
      const { id, content } = action.payload;
      const message = state.find(msg => msg.id === id);
      if (message) {
        message.content = content;
      }
    },
  },
});

export const { setMessages, addMessage, updateMessageContent } = messagesSlice.actions;
export default messagesSlice.reducer;
