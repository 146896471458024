// slices/botOptionsSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { defaultOptions } from '../../services/BotOptionsService';

const botOptionsSlice = createSlice({
  name: 'botOptions',
  initialState: defaultOptions,
  reducers: {
    setBotOptions: (state, action) => action.payload,
  },
});

export const { setBotOptions } = botOptionsSlice.actions;
export default botOptionsSlice.reducer;
