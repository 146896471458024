// slices/pathsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const pathsSlice = createSlice({
  name: 'paths',
  initialState: [],
  reducers: {
    setPaths: (state, action) => action.payload,
    addPath: (state, action) => {
      state.push(action.payload);
    }
  },
});

export const { setPaths, addPath } = pathsSlice.actions;
export default pathsSlice.reducer;
