import { useEffect, useState, useRef } from "react";

import ChatBotHeader from "./ChatBotHeader/ChatBotHeader";
import ChatBotBody from "./ChatBotBody/ChatBotBody";
import ChatBotInput from "./ChatBotInput/ChatBotInput";
import ChatBotFooter from "./ChatBotFooter/ChatBotFooter";
import ChatBotButton from "./ChatBotButton/ChatBotButton";
import ChatBotTooltip from "./ChatBotTooltip/ChatBotTooltip";
import ChatHistoryButton from "./ChatHistoryButton/ChatHistoryButton";
import { preProcessBlock, postProcessBlock } from "../services/BlockService/BlockService";
import { loadChatHistory, saveChatHistory, setHistoryStorageValues } from "../services/ChatHistoryService";
import { processAudio } from "../services/AudioService";
import { syncVoiceWithChatInput } from "../services/VoiceService";
import { isChatBotVisible, isDesktop, parseMarkupMessage } from "../services/Utils";
import "./ChatBotContainer.css";
import { useDispatch, useSelector } from "react-redux";
import { setBotOptions } from "../redux/slices/botOptionsSlice";
import { addMessage } from "../redux/slices/messagesSlice";
import { addPath } from "../redux/slices/pathsSlice";

const ChatBotContainer = ({ flow }) => {


	// const { botOptions, setBotOptions } = useBotOptions();
	const botOptions = useSelector((state)=>state.botOptions)
	console.log("botoptions",botOptions)
	
	const dispatch = useDispatch();

	const reduxMessages = useSelector((state)=>state.messages)
	const [messages, setMessages] = useState(reduxMessages)

	console.log(messages,reduxMessages)
	

	const reduxPaths = useSelector((state)=>state.paths)
	const [paths, setPaths] = useState(reduxPaths)
	console.log("patgs", paths)

	const chatBodyRef = useRef(null);


	const inputRef = useRef(null);


	const paramsInputRef = useRef("");


	const isBotStreamingRef = useRef(false);


	const keepVoiceOnRef = useRef(false);


	const audioContextRef = useRef(null);
	const audioBufferRef = useRef();
	const gainNodeRef = useRef(null);


	const [hasInteractedPage, setHasInteractedPage] = useState(false);


	const [hasFlowStarted, setHasFlowStarted] = useState(false);


	const [notificationToggledOn, setNotificationToggledOn] = useState(true);


	const [audioToggledOn, setAudioToggledOn] = useState(false);


	const [voiceToggledOn, setVoiceToggledOn] = useState(false);


	const [textAreaDisabled, setTextAreaDisabled] = useState(false);


	const [textAreaSensitiveMode, setTextAreaSensitiveMode] = useState(false);


	const [isLoadingChatHistory, setIsLoadingChatHistory] = useState(false);


	const [isScrolling, setIsScrolling] = useState(false);


	const [chatScrollHeight, setChatScrollHeight] = useState(0);


	const [isBotTyping, setIsBotTyping] = useState(false);


	const [timeoutId, setTimeoutId] = useState();


	const [unreadCount, setUnreadCount] = useState(0);


	const [viewportHeight, setViewportHeight] = useState(window?.visualViewport?.height
		|| window?.innerHeight);
	const [viewportWidth, setViewportWidth] = useState(window?.visualViewport?.width
		|| window?.innerWidth);


	const scrollPositionRef = useRef(0);


	useEffect(() => {
		window.addEventListener("click", handleFirstInteraction);
		window.addEventListener("keydown", handleFirstInteraction);
		window.addEventListener("touchstart", handleFirstInteraction);

		setUpNotifications();
		setTextAreaDisabled(botOptions.chatInput?.disabled);
		setAudioToggledOn(botOptions.audio?.defaultToggledOn);
		setVoiceToggledOn(botOptions.voice?.defaultToggledOn);
		if (botOptions.chatHistory?.disabled) {
			localStorage.removeItem(botOptions.chatHistory?.storageKey);
		} else {
			const chatHistory = localStorage.getItem(botOptions.chatHistory?.storageKey);
			console.log(chatHistory)
			if (chatHistory != null) {

				const messageContent = {
					content: <ChatHistoryButton chatHistory={chatHistory} showChatHistory={showChatHistory} />,
					sender: "system"
				};
				setMessages([messageContent]);
				if (botOptions.chatHistory?.autoLoad) {
					loadChatHistory(botOptions, chatHistory, setMessages, setTextAreaDisabled);
				}
			}
		}

		return () => {
			window.removeEventListener("click", handleFirstInteraction);
			window.removeEventListener("keydown", handleFirstInteraction);
			window.removeEventListener("touchstart", handleFirstInteraction);
		};
	}, []);


	useEffect(() => {
		setHistoryStorageValues(botOptions);
	}, [botOptions.chatHistory?.storageKey, botOptions.chatHistory?.maxEntries, botOptions.chatHistory?.disabled]);


	useEffect(() => {

		if (isDesktop || botOptions.theme?.embedded) {
			return;
		}

		if (navigator.virtualKeyboard) {
			navigator.virtualKeyboard.overlaysContent = true;
			navigator.virtualKeyboard.addEventListener("geometrychange", (event) => {
				if (!event.target) {
					return;
				}

				const { x, y, width, height } = (event.target).boundingRect;

				if (x == 0 && y == 0 && width == 0 && height == 0) {

					setTimeout(() => {
						setViewportHeight(window.visualViewport?.height);
					}, 101);


					setTimeout(() => {
						if (viewportHeight != window.visualViewport?.height) {
							setViewportHeight(window.visualViewport?.height);
						}
					}, 1001);
				} else {

					setTimeout(() => {
						setViewportHeight(window.visualViewport?.height - height);
					}, 101);
				}
			});
		}
	}, [])


	useEffect(() => {
		saveChatHistory(messages);
		handleNotifications();
	}, [messages.length]);


	useEffect(() => {
		if (!isBotStreamingRef.current) {
			saveChatHistory(messages);
		}
	}, [isBotStreamingRef.current])


	useEffect(() => {
		if (botOptions.isOpen) {
			setUnreadCount(0);
			setViewportHeight(window.visualViewport?.height);
			setViewportWidth(window.visualViewport?.width);
		}

		if (isDesktop) {
			return;
		}


		const handleMobileScrollOpened = () => window.scrollTo({ top: 0, left: 0, behavior: "auto" });

		const handleMobileScrollClosed = () => scrollPositionRef.current = window.scrollY;
		const handleResize = () => {
			setViewportHeight(window.visualViewport?.height);
			setViewportWidth(window.visualViewport?.width);
		}

		const cleanupScrollEventListeners = () => {
			window.removeEventListener("scroll", handleMobileScrollOpened);
			window.removeEventListener("scroll", handleMobileScrollClosed);
			window.visualViewport?.removeEventListener("resize", handleResize);
		};

		if (botOptions.isOpen) {
			cleanupScrollEventListeners();
			document.body.style.position = "fixed";
			window.addEventListener("scroll", handleMobileScrollOpened);
			window.visualViewport?.addEventListener("resize", handleResize);
		} else {
			document.body.style.position = "static";
			cleanupScrollEventListeners();
			window.scrollTo({ top: scrollPositionRef.current, left: 0, behavior: "auto" });
			window.addEventListener("scroll", handleMobileScrollClosed);
			window.visualViewport?.removeEventListener("resize", handleResize);
		}

		return cleanupScrollEventListeners;
	}, [botOptions.isOpen]);


	useEffect(() => {
		const currPath = getCurrPath();
		if (!currPath) {
			return;
		}
		const block = flow[currPath];


		if (!block) {
			setIsBotTyping(false);
			return;
		}

		const params = {
			prevPath: getPrevPath(), userInput: paramsInputRef.current,
			injectMessage, streamMessage, openChat
		};
		callNewBlock(currPath, block, params);
	}, [paths]);

	useEffect(() => {
		if (hasFlowStarted || botOptions.theme?.flowStartTrigger === "ON_LOAD") {
			setPaths(["start"]);
		}
	}, [hasFlowStarted]);

	const callNewBlock = async (currPath, block, params) => {

		if (currPath === "start") {
			setTextAreaDisabled(true);
		}

		await preProcessBlock(flow, currPath, params, setTextAreaDisabled, setTextAreaSensitiveMode,
			dispatch, setTimeoutId, handleActionInput);


		setIsBotTyping(false);
		updateTextArea();
		syncVoiceWithChatInput(keepVoiceOnRef.current && !block.chatDisabled, botOptions);


		isBotStreamingRef.current = false
	}

	/**
	 * Sets up the notifications feature (initial toggle status and sound).
	 */
	const setUpNotifications = async () => {
		setNotificationToggledOn(botOptions.notification?.defaultToggledOn);

		const notificationSound = botOptions.notification?.sound;
		audioContextRef.current = new AudioContext();
		const gainNode = audioContextRef.current.createGain();
		gainNode.gain.value = botOptions.notification?.volume || 0.2;
		gainNodeRef.current = gainNode;

		let audioSource;
		if (notificationSound?.startsWith("data:audio")) {
			const binaryString = atob(notificationSound.split(",")[1]);
			const arrayBuffer = new ArrayBuffer(binaryString.length);
			const uint8Array = new Uint8Array(arrayBuffer);
			for (let i = 0; i < binaryString.length; i++) {
				uint8Array[i] = binaryString.charCodeAt(i);
			}
			audioSource = arrayBuffer;
		} else {
			const response = await fetch(notificationSound);
			audioSource = await response.arrayBuffer();
		}

		audioBufferRef.current = await audioContextRef.current.decodeAudioData(audioSource);
	}

	const handleFirstInteraction = () => {
		setHasInteractedPage(true);
		if (!hasFlowStarted && botOptions.theme?.flowStartTrigger === "ON_PAGE_INTERACT") {
			setHasFlowStarted(true);
		}


		const utterance = new SpeechSynthesisUtterance();
		utterance.text = "";
		utterance.onend = () => {
			window.removeEventListener("click", handleFirstInteraction);
			window.removeEventListener("keydown", handleFirstInteraction);
			window.removeEventListener("touchstart", handleFirstInteraction);
		};
		window.speechSynthesis.speak(utterance);
	};


	const openChat = (isOpen) => {
		setBotOptions({...botOptions, isOpen});
	}

	const handleNotifications = () => {

		if (!audioContextRef.current || messages.length === 0) {
			return;
		}

		const message = messages[messages.length - 1]

		if (!message || message.sender === "user" || isBotTyping || (botOptions.theme?.embedded
			&& isChatBotVisible(chatBodyRef.current))) {
			return;
		}


		if (botOptions.isOpen && !isScrolling) {
			return;
		}

		setUnreadCount(prev => prev + 1);
		if (!botOptions.notification?.disabled && notificationToggledOn
			&& hasInteractedPage && audioBufferRef.current) {
			const source = audioContextRef.current.createBufferSource();
			source.buffer = audioBufferRef.current;
			source.connect(gainNodeRef.current).connect(audioContextRef.current.destination);
			source.start();
		}
	}

	const getCurrPath = () => {
		return paths.length > 0 ? paths[paths.length - 1] : null;
	}

	const getPrevPath = () => {
		return paths.length > 1 ? paths[paths.length - 2] : null;
	}

	const injectMessage = async (content, sender = "bot") => {
		const message = { content: content, sender: sender };
		processAudio(botOptions, audioToggledOn, message);

		const isBotStream = typeof message.content === "string"
			&& message.sender === "bot" && botOptions?.botBubble?.simStream;
		const isUserStream = typeof message.content === "string"
			&& message.sender === "user" && botOptions?.userBubble?.simStream;

		dispatch(addMessage(message))
		if (isBotStream) {
			const streamSpeed = botOptions.botBubble?.streamSpeed;
			const useMarkup = botOptions.botBubble?.dangerouslySetInnerHtml;
			await simulateStream(message, streamSpeed, useMarkup);
		} else if (isUserStream) {
			const streamSpeed = botOptions.userBubble?.streamSpeed;
			const useMarkup = botOptions.userBubble?.dangerouslySetInnerHtml;
			await simulateStream(message, streamSpeed, useMarkup);
		} else {
			setMessages((prevMessages) => [...prevMessages, message]);
		}
	}

	const simulateStream = async (message, streamSpeed, useMarkup) => {

		setIsBotTyping(false);


		setMessages(prevMessages => [...prevMessages, message]);
		isBotStreamingRef.current = true


		let streamMessage = message.content;
		if (useMarkup) {
			streamMessage = parseMarkupMessage(streamMessage);
		}
		let streamIndex = 0;
		const endStreamIndex = streamMessage.length;

		message = { ...message, content : ""}

		const simStreamDoneTask = new Promise(resolve => {
			const intervalId = setInterval(() => {


				if (streamIndex >= endStreamIndex) {
					clearInterval(intervalId);
					resolve();
					return;
				}

				setMessages((prevMessages) => {
					const updatedMessages = [...prevMessages];
					for (let i = updatedMessages.length - 1; i >= 0; i--) {
						if (updatedMessages[i].sender === message.sender
							&& typeof updatedMessages[i].content === "string") {
							const character = streamMessage[streamIndex];
							if (character) {
								message.content += character;
								updatedMessages[i] = message;
							}
							streamIndex++;
							break;
						}
					}
					return updatedMessages;
				});
			}, streamSpeed);
		});

		await simStreamDoneTask;
		isBotStreamingRef.current = false;
	};

	const streamMessage = async (content, sender = "bot") => {
		const message = { content: content, sender: sender };


		if (!isBotStreamingRef.current) {
			setIsBotTyping(false);
			setMessages(prevMessages => [...prevMessages, message]);
			isBotStreamingRef.current = true;
			return;
		}

		setMessages((prevMessages) => {
			const updatedMessages = [...prevMessages];

			for (let i = updatedMessages.length - 1; i >= 0; i--) {
				if (updatedMessages[i].sender === sender && typeof updatedMessages[i].content === typeof content) {
					updatedMessages[i] = message;
					break;
				}
			}

			return updatedMessages;
		});
	}

	const showChatHistory = (chatHistory) => {
		setIsLoadingChatHistory(true);
		setTextAreaDisabled(true);
		console.log(loadChatHistory(botOptions, chatHistory, setMessages, setTextAreaDisabled));
	}

	const updateTextArea = () => {
		const currPath = getCurrPath();
		if (!currPath) {
			return;
		}
		const block = flow[currPath];

		if (!block) {
			return;
		}

		const shouldDisableTextArea = block.chatDisabled
			? block.chatDisabled
			: botOptions.chatInput?.disabled;
		setTextAreaDisabled(shouldDisableTextArea);

		if (!shouldDisableTextArea) {
			setTimeout(() => {
				if (botOptions.theme?.embedded) {

					if (isChatBotVisible(chatBodyRef.current)) {
						inputRef.current?.focus();
					}
				} else {

					if (currPath !== "start") {
						inputRef.current?.focus();
					}
				}
			}, 100)
		}

		if (block.isSensitive) {
			setTextAreaSensitiveMode(block.isSensitive);
		} else {
			setTextAreaSensitiveMode(false);
		}
	}

	const handleToggleNotification = () => {
		setNotificationToggledOn(prev => !prev);
	}

	const handleToggleAudio = () => {
		setAudioToggledOn(prev => !prev);
	}

	const handleToggleVoice = () => {
		if (textAreaDisabled) {
			return;
		}
		setVoiceToggledOn(prev => !prev);
	}

	const handleActionInput = async (path, userInput, sendUserInput = true) => {
		clearTimeout(timeoutId);
		userInput = userInput.trim();
		paramsInputRef.current = userInput;

		if (userInput === "") {
			return;
		}


		if (sendUserInput) {
			await handleSendUserInput(userInput);
		}

		if (chatBodyRef.current) {
			chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
		}



		if (inputRef.current) {
			inputRef.current.value = "";
		}

		if (botOptions.chatInput?.blockSpam) {
			setTextAreaDisabled(true);
		}


		keepVoiceOnRef.current = voiceToggledOn;
		syncVoiceWithChatInput(false, botOptions);

		setTimeout(() => {
			setIsBotTyping(true);
		}, 400);

		setTimeout(async () => {
			const params = { prevPath: getPrevPath(), userInput, injectMessage, streamMessage, openChat };
			const hasNextPath = await postProcessBlock(flow, path, params, setPaths);
			if (!hasNextPath) {
				updateTextArea();
				syncVoiceWithChatInput(keepVoiceOnRef.current, botOptions);
				setIsBotTyping(false);
			}
		}, botOptions.chatInput?.botDelay);
	}

	const handleSendUserInput = async (userInput) => {
		const currPath = getCurrPath();
		if (!currPath) {
			return;
		}

		const block = flow[currPath];
		if (!block) {
			return;
		}

		if (block.isSensitive) {
			if (botOptions?.sensitiveInput?.hideInUserBubble) {
				return;
			} else if (botOptions?.sensitiveInput?.maskInUserBubble) {
				await injectMessage("*".repeat(botOptions.sensitiveInput?.asterisksCount || 10), "user");
				return;
			}
		}

		await injectMessage(userInput, "user");
	}

	/**
	 * Retrieves class name for window state.
	 */
	const getWindowStateClass = () => {
		const windowClass = "botai-chat-bot ";
		if (botOptions.theme?.embedded) {
			return windowClass + "botai-window-embedded";
		} else if (botOptions.isOpen) {
			return windowClass + "botai-window-open";
		} else {
			return windowClass + "botai-window-close"
		}
	}


	const getChatWindowStyle = () => {
		if (!isDesktop && !botOptions.theme?.embedded) {
			return {
				...botOptions.chatWindowStyle,
				borderRadius: "0px",
				left: "0px",
				right: "auto",
				top: "0px",
				bottom: "auto",
				width: `${viewportWidth}px`,
				height: `${viewportHeight}px`,
			}
		} else {
			return botOptions.chatWindowStyle;
		}
	}

	return (
		<div
			onMouseDown={(event) => {

				if (!hasFlowStarted && botOptions.theme?.flowStartTrigger === "ON_CHATBOT_INTERACT") {
					setHasFlowStarted(true);
				}


				if (isDesktop) {
					inputRef.current?.blur();
				} else {
					event?.preventDefault();
				}
			}}
			className={getWindowStateClass()}
		>
			<ChatBotTooltip />
			<ChatBotButton unreadCount={unreadCount} />
			{botOptions.isOpen && !isDesktop && !botOptions.theme?.embedded &&
				<>
					<style>
						{`
							html {
								overflow: hidden !important;
								touch-action: none !important;
								scroll-behavior: auto !important;
							}
						`}
					</style>
					<div
						style={{
							position: "fixed",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							backgroundColor: "#fff",
							zIndex: 9999
						}}
					>
					</div>
				</>
			}
			<div

				style={{
					width: '100%',
					height: '100%',
				}}
				className="botai-chat-window"
			>
				{botOptions.theme?.showHeader &&
					<ChatBotHeader notificationToggledOn={notificationToggledOn}
						handleToggleNotification={handleToggleNotification}
						audioToggledOn={audioToggledOn} handleToggleAudio={handleToggleAudio}
					/>
				}
				{botOptions.theme?.showFooter &&
					<ChatBotFooter inputRef={inputRef} flow={flow} textAreaDisabled={textAreaDisabled}
						handleActionInput={handleActionInput} injectMessage={injectMessage}
						streamMessage={streamMessage} getCurrPath={getCurrPath} getPrevPath={getPrevPath}
						openChat={openChat}
					/>
				}
				<ChatBotBody historyChatData={messages} chatBodyRef={chatBodyRef} isBotTyping={isBotTyping}
					isLoadingChatHistory={isLoadingChatHistory} chatScrollHeight={chatScrollHeight}
					setChatScrollHeight={setChatScrollHeight} setIsLoadingChatHistory={setIsLoadingChatHistory}
					isScrolling={isScrolling} setIsScrolling={setIsScrolling}
					unreadCount={unreadCount} setUnreadCount={setUnreadCount} 
				/>

				{botOptions.theme?.showInputRow &&
					<ChatBotInput handleToggleVoice={handleToggleVoice} handleActionInput={handleActionInput}
						inputRef={inputRef} textAreaDisabled={textAreaDisabled}
						textAreaSensitiveMode={textAreaSensitiveMode}
						voiceToggledOn={voiceToggledOn} getCurrPath={getCurrPath}
						hasFlowStarted={hasFlowStarted} setHasFlowStarted={setHasFlowStarted}
					/>
				}
			</div>
		</div>
	);
};

export default ChatBotContainer;