import { RefObject, Dispatch, SetStateAction, useEffect, MouseEvent } from "react";

import { startVoiceRecording, stopVoiceRecording } from "../../../services/VoiceService";

import "./VoiceButton.css";
import { useDispatch, useSelector } from "react-redux";

/**
 * Toggles voice to text input to the chat bot.
 *
 * @param inputRef reference to the textarea
 * @param textAreaDisabled boolean indicating if textarea is disabled
 * @param voiceToggledOn boolean indicating if voice is toggled on
 * @param handleToggleVoice handles toggling of voice
 * @param triggerSendVoiceInput triggers sending of voice input into chat window
 * @param setInputLength sets the input length to reflect character count & limit
 */
const VoiceButton = ({
	inputRef,
	textAreaDisabled,
	voiceToggledOn,
	handleToggleVoice,
	triggerSendVoiceInput,
	setInputLength
}) => {

	// handles options for bot
	const dispatch = useDispatch();
	const botOptions = useSelector((state) => state.botOptions);
	
	// handles starting and stopping of voice recording on toggle
	useEffect(() => {
		if (voiceToggledOn) {
			startVoiceRecording(botOptions, handleToggleVoice, triggerSendVoiceInput, setInputLength, inputRef, dispatch);
		} else {
			stopVoiceRecording();
		}
	}, [voiceToggledOn]);

	return (
		<div
			onMouseDown={(event) => {
				event.preventDefault();
				handleToggleVoice();
			}}
			className={voiceToggledOn && !textAreaDisabled ? "botai-voice-button-enabled" : "botai-voice-button-disabled"}
		>
			<span className={voiceToggledOn && !textAreaDisabled ? "botai-voice-icon-on" : "botai-voice-icon-off"}
				style={{backgroundImage: `url(${botOptions.voice?.icon})`}}
			/>
		</div>
	);
};

export default VoiceButton;