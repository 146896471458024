

const helpOptions = ["Quickstart", "API Docs", "Examples", "Github", "Discord"];

export const defaultFlow = {
	start: {
		message: "Hello, I am Wall of Andhra AI 👋! I'm excited that you are using our " +
			"chatbot 😊!",
		transition: {duration: 1000},
		chatDisabled: true,
		path: "show_options"
	},
	show_options: {
		message: "It looks like you have not set up a conversation flow yet. No worries! Here are a few helpful " +
			"things you can check out to get started:",
		options: helpOptions,
		path: "process_options"
	},
	prompt_again: {
		message: "Do you need any other help?",
		options: helpOptions,
		path: "process_options"
	},
	unknown_input: {
		message: "Sorry, I do not understand your message 😢! If you require further assistance, you may click on " +
			"the Github option and open an issue there or visit our discord.",
		options: helpOptions,
		path: "process_options"
	},
	process_options: {
		transition: {duration: 0},
		path: (params) => {
			let link = "";
			switch (params.userInput) {
			case "Quickstart":
				link = "https://react-chatbotify.tjtanjin.com/docs/introduction/quickstart/";
				break;
			case "API Docs":
				link = "https://react-chatbotify.tjtanjin.com/docs/api/bot_options";
				break;
			case "Examples":
				link = "https://react-chatbotify.tjtanjin.com/docs/examples/basic_form";
				break;
			case "Github":
				link = "https://github.com/tjtanjin/react-chatbotify/";
				break;
			case "Discord":
				link = "https://discord.gg/6R4DK4G5Zh";
				break;
			default:
				return "unknown_input";
			}
			params.injectMessage("Sit tight! I'll send you right there!");
			setTimeout(() => {
				window.open(link);
			}, 1000)
			return "repeat"
		},
	},
	repeat: {
		transition: {duration: 3000},
		path: "prompt_again"
	},
}

export const doctorflow = {
    start: {
      message: "Hello! What is your name?",
      path: "ask_age",
    },
    ask_age: {
      message: (params) => `Nice to meet you, ${params.userInput}! How old are you?`,
      path: "ask_health_problem",
    },
    ask_health_problem: {
      message: "What health issue are you experiencing?",
      options: ["General Checkup", "Cold/Flu", "Headache", "Stomach Ache", "Other"],
      chatDisabled: true,
      path: (params) => (params.userInput === "Other" ? "ask_custom_health_problem" : "ask_symptoms"),
    },
    ask_custom_health_problem: {
      message: "Please describe your health issue.",
      path: "ask_symptoms",
    },
    ask_symptoms: {
      message: "Could you describe your symptoms?",
      path: "ask_duration",
    },
    ask_duration: {
      message: "How long have you been experiencing these symptoms?",
      path: "ask_severity",
    },
    ask_severity: {
      message: "How severe are your symptoms on a scale of 1-10?",
      path: "ask_contact_details",
    },
    ask_contact_details: {
      message: "Please provide your contact details.",
      form: {
        fields: [
          { label: "Email", type: "email" },
          { label: "Phone Number", type: "text" },
        ],
      },
      path: "ask_appointment_time",
    },
    ask_appointment_time: {
      message: "When would you like to book your appointment?",
      options: ["Today", "Tomorrow", "Later This Week"],
      chatDisabled: true,
      path: "confirm_appointment",
    },
    confirm_appointment: {
      message: (params) => {
        return (
          <div>
            <p>Thank you! You have booked an appointment for {params.userInput}. Here are the details:</p>
            <ul>
              {/* <li>Name: {fakeData.name}</li>
              <li>Email: {fakeData.email}</li>
              <li>Phone: {fakeData.phone}</li>
              <li>Appointment Date: {fakeData.appointmentDate}</li>
              <li>Symptoms: {fakeData.symptoms}</li>
              <li>Severity: {fakeData.severity}</li> */}
            </ul>
          </div>
        );
      },
      path: "ask_feedback",
    },
    ask_feedback: {
      message: "How would you rate your experience with our chatbot?",
      options: ["1", "2", "3", "4", "5"],
      chatDisabled: true,
      path: "ask_image",
    },
	ask_image: {
		message: (params) => `${params.userInput}? Interesting. Could you share an image of that?`,
		file: (params) => console.log(params.files),
		path: "end"
	},
    end: {
      message: "Thank you for your feedback! Have a great day!",
      path: "loop",
    },
    loop: {
      message: "You have reached the end of the conversation!",
      path: "loop",
    },
  };

export const isDesktop = !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

export const parseMarkupMessage = (message) => {
	const result = [];
	let currentTag = "";
	let isInTag = false;

	for (let i = 0; i < message.length; i++) {
		const char = message[i];

		if (char === "<") {
			if (!isInTag) {
				isInTag = true;
				currentTag = char;
			} else {
				result.push(currentTag);
				currentTag = char;
			}
		} else if (char === ">") {
			currentTag += char;
			result.push(currentTag);
			currentTag = "";
			isInTag = false;
		} else {
			if (isInTag) {
				currentTag += char;
			} else {
				result.push(char);
			}
		}
	}
  
	if (currentTag !== "") {
		result.push(currentTag);
	}
	return result;
}

export const isChatBotVisible = (element) => {
	if (!element) {
		return false;
	}

	const rect = element.getBoundingClientRect();
	const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
	const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= windowHeight &&
		rect.right <= windowWidth
	);
}
