import { useState } from "react";

import "./ChatMessagePrompt.css";
import { useSelector } from "react-redux";

const ChatMessagePrompt = ({
	chatBodyRef,
	isScrolling,
	setIsScrolling,
	unreadCount,
}) => {

	
	const botOptions = useSelector((state) => state.botOptions);

	
	const [isHovered, setIsHovered] = useState(false);

	
	const chatMessagePromptHoveredStyle = {
		color: botOptions.theme?.primaryColor,
		borderColor: botOptions.theme?.primaryColor,
		...botOptions.chatMessagePromptHoveredStyle
	};

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	function scrollToBottom(duration) {
		if (!chatBodyRef.current) {
			return;
		}

		const start = chatBodyRef.current.scrollTop;
		const end = chatBodyRef.current.scrollHeight - chatBodyRef.current.clientHeight;
		const change = end - start;
		const increment = 20;
		let currentTime = 0;
	
		function animateScroll() {
			if (!chatBodyRef.current) {
				return;
			}
			currentTime += increment;
			const val = easeInOutQuad(currentTime, start, change, duration);
			chatBodyRef.current.scrollTop = val;
			if (currentTime < duration) {
				requestAnimationFrame(animateScroll);
			} else {
				setIsScrolling(false);
			}
		}
		
		animateScroll();
	}

	const easeInOutQuad = (t, b, c, d) => {
		t /= d / 2;
		if (t < 1) return c / 2 * t * t + b;
		t--;
		return -c / 2 * (t * (t - 2) - 1) + b;
	};

	const getMessagePromptVisibility = () => {
		const shouldShowPrompt = chatBodyRef.current
            && botOptions.chatWindow?.showMessagePrompt
            && isScrolling
            && unreadCount > 0;
		return shouldShowPrompt ? "visible" : "hidden";
	};

	return (
		<div className={`botai-message-prompt-container ${getMessagePromptVisibility()}`}>
			<div
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave} 
				style={isHovered ? chatMessagePromptHoveredStyle : botOptions.chatMessagePromptStyle}
				onMouseDown={(event) => {
					event.preventDefault();
					scrollToBottom(600);
				}}
				className="botai-message-prompt-text"
			>
				{botOptions.chatWindow?.messagePromptText}
			</div>
		</div>
	);
};

export default ChatMessagePrompt;