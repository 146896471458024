import { useState, MouseEvent } from "react";
import "./ChatHistoryButton.css";
import { useSelector } from "react-redux";

/**
 * Supports viewing of old messages.
 * 
 * @param chatHistory string representation of old chat messages
 * @param showChatHistory entry point for showing of chat history
 */
const ChatHistoryButton = ({
	chatHistory,
	showChatHistory
}) => {

	// handles options for bot
	const botOptions = useSelector((state) => state.botOptions);

	// tracks if view history button is hovered
	const [isHovered, setIsHovered] = useState(false);

	// styles for view chat history hovered button
	const chatHistoryButtonHoveredStyle = {
		color: botOptions.theme?.primaryColor,
		borderColor: botOptions.theme?.primaryColor,
		...botOptions.chatHistoryButtonHoveredStyle
	};

	/**
	 * Handles mouse enter event on view chat history button.
	 */
	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	/**
	 * Handles mouse leave event on view chat history button.
	 */
	const handleMouseLeave = () => {
		setIsHovered(false);
	};
	
	return (
		<div className="botai-view-history-container">
			<div
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave} 
				style={isHovered ? chatHistoryButtonHoveredStyle : botOptions.chatHistoryButtonStyle}
				onMouseDown={(event) => {
					event.preventDefault();
					showChatHistory(chatHistory);
				}}
				className="botai-view-history-button"
			>
				<p>
					{botOptions.chatHistory?.viewChatHistoryButtonText}
				</p>
			</div>
		</div>
	);
};

export default ChatHistoryButton;