import { RefObject, Dispatch, SetStateAction, useEffect, CSSProperties, MouseEvent } from "react";

import ChatMessagePrompt from "./ChatMessagePrompt/ChatMessagePrompt";

import "./ChatBotBody.css";
import { useSelector } from "react-redux";

const ChatBotBody = ({
	chatBodyRef,
	isBotTyping,
	isLoadingChatHistory,
	chatScrollHeight,
	setChatScrollHeight,
	setIsLoadingChatHistory,
	isScrolling: isScrolling,
	setIsScrolling,
	unreadCount,
	setUnreadCount,
	historyChatData
}) => {

	// handles options for bot
	const botOptions = useSelector((state)=>state.botOptions)

	const messages = useSelector((state)=>state.messages)

	// styles for chat body
	const bodyStyle = {
		...botOptions?.bodyStyle,
		scrollbarWidth: botOptions.chatWindow?.showScrollbar ? "auto" : "none",
	}

	// styles for user bubble
	const userBubbleStyle = {
		backgroundColor: botOptions.theme?.primaryColor,
		color: "#fff",
		maxWidth: botOptions.userBubble?.showAvatar ? "65%" : "70%",
		...botOptions.userBubbleStyle
	};
	const userBubbleEntryStyle = botOptions.userBubble?.animate ? "botai-user-message-entry" : "";

	// styles for bot bubble
	const botBubbleStyle = {
		backgroundColor: botOptions.theme?.secondaryColor,
		color: "#fff",
		maxWidth: botOptions.botBubble?.showAvatar ? "65%" : "70%",
		...botOptions.botBubbleStyle
	};
	const botBubbleEntryStyle = botOptions.botBubble?.animate ? "botai-bot-message-entry" : "";

	// shifts scroll position when messages are updated and when bot is typing
	useEffect(() => {
		if (!chatBodyRef.current) {
			return;
		}

		if (isLoadingChatHistory) {
			const { scrollHeight } = chatBodyRef.current;
			const scrollDifference = scrollHeight - chatScrollHeight;
			chatBodyRef.current.scrollTop = chatBodyRef.current.scrollTop + scrollDifference;
			setIsLoadingChatHistory(false);
			return;
		}

		if (botOptions.chatWindow?.autoJumpToBottom || !isScrolling) {
			chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
			if (botOptions.isOpen) {
				setUnreadCount(0);
			}
		}
	}, [messages.length, isBotTyping]);

	// shifts scroll position when scroll height changes
	useEffect(() => {
		if (!chatBodyRef.current) {
			return;
		}

		// used to return chat history to correct height
		setChatScrollHeight(chatBodyRef.current.scrollHeight);

		if (!isScrolling) {
			chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
			if (botOptions.isOpen) {
				setUnreadCount(0);
			}
		}
	}, [chatBodyRef.current?.scrollHeight]);

	// sets unread count to 0 if not scrolling
	useEffect(() => {
		if (!isScrolling) {
			setUnreadCount(0);
		}
	}, [isScrolling]);

	/**
	 * Checks and updates whether a user is scrolling in chat window.
	 */
	const updateIsScrolling = () => {
		if (!chatBodyRef.current) {
			return;
		}
		const { scrollTop, clientHeight, scrollHeight } = chatBodyRef.current;
		setIsScrolling(
			scrollTop + clientHeight < scrollHeight - (botOptions.chatWindow?.messagePromptOffset || 30)
		);

		// workaround to ensure user never truly scrolls to bottom by introducing a 1 pixel offset
		// this is necessary to prevent unexpected scroll behaviors of the chat window when user reaches the bottom
		if (!isScrolling && scrollTop + clientHeight >= scrollHeight - 1) {
			chatBodyRef.current.scrollTop = scrollHeight - clientHeight - 1;
		}
	};

	const renderUserMessage = (message) => {
		if (message.type === 'audio') {
			return (
				<div style={userBubbleStyle} className={`botai-user-message ${userBubbleEntryStyle}`}>
					<audio controls src={message.content} />
				</div>
			);
		}
	
		return (
			<>
				{botOptions?.userBubble?.dangerouslySetInnerHtml ?
					<div
						style={{...userBubbleStyle, display: "inline" }}
						className={`botai-user-message ${userBubbleEntryStyle}`}
						dangerouslySetInnerHTML={{__html: message.content}}
					/>
					:
					<div
						style={userBubbleStyle}
						className={`botai-user-message ${userBubbleEntryStyle}`}
					>
						{message.content}
					</div>
				}
				{botOptions.userBubble?.showAvatar &&
					<div 
						style={{backgroundImage: `url(${botOptions.userBubble?.avatar})`}}
						className="botai-message-user-avatar"
					/>
				}
			</>
		);
	};
	
	const renderBotMessage = (message) => {
		if (message.type === 'audio') {
			return (
				<div style={botBubbleStyle} className={`botai-bot-message ${botBubbleEntryStyle}`}>
					<audio controls src={message.content} />
				</div>
			);
		}
	
		return (
			<>
				{botOptions.botBubble?.showAvatar &&
					<div
						style={{backgroundImage: `url(${botOptions.botBubble?.avatar})`}}
						className="botai-message-bot-avatar"
					/>
				}
				{botOptions?.botBubble?.dangerouslySetInnerHtml ?
					<div
						style={{...botBubbleStyle, display: "inline" }}
						className={`botai-bot-message ${botBubbleEntryStyle}`}
						dangerouslySetInnerHTML={{__html: message.content}}
					/>
					:
					<div
						style={botBubbleStyle}
						className={`botai-bot-message ${botBubbleEntryStyle}`}
					>
						{message.content}
					</div>
				}
			</>
		);
	};
	
	
	return (
		<div 
			style={bodyStyle}
			className="botai-chat-body-container"
			ref={chatBodyRef} 
			onScroll={updateIsScrolling}
		>
			{messages.map((message, index) => {
				if (typeof message.content !== "string") {
					return <div key={index}>{message.content}</div>;
				}
		
				return (
					<div 
						key={index} 
						className={message.sender === "user"
							? "botai-user-message-container"
							: "botai-bot-message-container"
						}
					>
						{message.sender === "user" ? renderUserMessage(message) : renderBotMessage(message)}
					</div>
				);
			})}
			{isBotTyping && (
				<div className="botai-bot-message-container">
					{botOptions.botBubble?.showAvatar &&
						<div 
							style={{backgroundImage: `url(${botOptions.botBubble?.avatar})`}}
							className="botai-message-bot-avatar"
						/>
					}
					<div 
						onMouseDown={(event) => {
							event.preventDefault();
						}}
						className={`botai-bot-message ${botBubbleEntryStyle}`}
					>
						<div className="botai-typing-indicator">
							<span className="botai-dot"/>
							<span className="botai-dot"/>
							<span className="botai-dot"/>
						</div>
					</div>
				</div>
			)}
			<ChatMessagePrompt
				chatBodyRef={chatBodyRef} isScrolling={isScrolling}
				setIsScrolling={setIsScrolling} unreadCount={unreadCount}
			/>
		</div>
	);
};

export default ChatBotBody;