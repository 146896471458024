import { MouseEvent } from "react";

import "./ChatBotHeader.css";
import { useDispatch, useSelector } from "react-redux";
import { setBotOptions } from "../../redux/slices/botOptionsSlice";

const ChatBotHeader = ({
	notificationToggledOn,
	audioToggledOn,
	handleToggleNotification,
	handleToggleAudio
}) => {

	const botOptions = useSelector((state) => state.botOptions);
	const dispatch = useDispatch();

	const headerStyle = {
		background: `linear-gradient(to right, ${botOptions.theme?.secondaryColor },
			${botOptions.theme?.primaryColor})`,
		...botOptions.headerStyle
	}

	// icons in header
	const headerImages = {
		headerAvatar: {
			backgroundImage: `url(${botOptions.header?.avatar})`,
		},
		notificationIcon: {
			backgroundImage: `url(${botOptions.notification?.icon})`,
		},
		audioIcon: {
			backgroundImage: `url(${botOptions.audio?.icon})`,
		},
		closeChatIcon: {
			backgroundImage: `url(${botOptions.header?.closeChatIcon})`,
		}
	};

	const handleCloseChat = () => {
		dispatch(setBotOptions({...botOptions, isOpen: false}));
	}

	return (
		<div style={headerStyle} className="botai-chat-header-container">
			<div className="botai-chat-header">
				{botOptions.header?.showAvatar &&
					<div style={headerImages.headerAvatar} className="botai-bot-avatar"/>
				}
				{botOptions.header?.title}
			</div>
			<div className="botai-chat-header">
				{!botOptions.notification?.disabled && !botOptions.theme?.embedded &&
					<div
						style={headerImages.notificationIcon}
						onMouseDown={(event) => {
							event.preventDefault();
							handleToggleNotification();
						}}
						className={`botai-notification-icon-${notificationToggledOn ? "on" : "off"}`}
					>
					</div>
				}
				{!botOptions.audio?.disabled &&
					<div
						style={headerImages.audioIcon}
						onMouseDown={(event) => {
							event.preventDefault();
							handleToggleAudio();
						}}
						className={`botai-audio-icon-${audioToggledOn ? "on" : "off"}`}
					>
					</div>
				}
				{!botOptions.theme?.embedded &&
					<div
						style={headerImages.closeChatIcon}
						onMouseDown={(event) => {
							event.stopPropagation();
							handleCloseChat();
						}}
						className="botai-close-chat-icon"
					>
					</div>
				}
			</div>
		</div>
	);
};

export default ChatBotHeader;