import { RefObject, Dispatch, SetStateAction } from "react";
import { addMessage } from "../redux/slices/messagesSlice";

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = SpeechRecognition != null ? new SpeechRecognition() : null;
let inactivityTimer;
let autoSendTimer;
let toggleOn = false;

export const startVoiceRecording = (botOptions, handleToggleVoice,
	triggerSendVoiceInput, setInputLength,
	inputRef, dispatch) => {

	if (!recognition) {
		return;
	}
	
	if (!toggleOn) {
		try {
			toggleOn = true;
			recognition.lang = botOptions.voice?.language;
			recognition.start();
		} catch {
			console.log("error in voice recording");
		}
	}

	const inactivityPeriod = botOptions.voice?.timeoutPeriod;
	const autoSendPeriod = botOptions.voice?.autoSendPeriod;

	recognition.onresult = event => {
		clearTimeout(inactivityTimer);
		inactivityTimer = null;
		clearTimeout(autoSendTimer);

		const voiceInput = event.results[event.results.length - 1][0].transcript;

		if (inputRef.current) {
			const characterLimit = botOptions.chatInput?.characterLimit
			const newInput = inputRef.current.value + voiceInput;
			dispatch(addMessage({content: newInput, sender:'user'}))
			if (characterLimit != null && characterLimit >= 0 && newInput.length > characterLimit) {
				inputRef.current.value = newInput.slice(0, characterLimit);
			} else {
				inputRef.current.value = newInput
			}
			setInputLength(inputRef.current.value.length);
		}

		inactivityTimer = setTimeout(() => handleTimeout(handleToggleVoice), inactivityPeriod);
		if (!botOptions.voice?.autoSendDisabled) {
			autoSendTimer = setTimeout(triggerSendVoiceInput, autoSendPeriod);
		}
	};

	recognition.onend = () => {
		if (toggleOn) {
			recognition.start();
			if (!inactivityTimer) {
				inactivityTimer = setTimeout(() => handleTimeout(handleToggleVoice), inactivityPeriod);
			}
		} else {
			clearTimeout(inactivityTimer);
			inactivityTimer = null;
			clearTimeout(autoSendTimer);
		}
	};

	inactivityTimer = setTimeout(() => handleTimeout(handleToggleVoice), inactivityPeriod);
}

export const stopVoiceRecording = () => {
	if (!recognition) {
		return;
	}

	toggleOn = false;
	if (recognition) {
		recognition.stop();
	}
}


export const syncVoiceWithChatInput = (keepVoiceOn, botOptions) => {

	if (botOptions.voice?.disabled || !botOptions.chatInput?.blockSpam || !recognition) {
		return;
	}

	if (keepVoiceOn && !toggleOn) {
		toggleOn = true;
		recognition.start();
	} else if (!keepVoiceOn) {
		stopVoiceRecording();
	}
}

const handleTimeout = (handleToggleVoice) => {
	handleToggleVoice();
	stopVoiceRecording();
}