import { useDispatch, useSelector } from "react-redux";

import "./ChatBotButton.css";
import { setBotOptions } from "../../redux/slices/botOptionsSlice";

const ChatBotButton = ({
	unreadCount
}) => {

	// handles options for bot
	const botOptions = useSelector((state) => state.botOptions);
	const dispatch = useDispatch();

	const toggleChatWindow = () => {
		dispatch(setBotOptions({...botOptions, isOpen: !botOptions.isOpen}));
	};

	// styles for chat button
	const chatButtonStyle = {
		backgroundImage: `url(${botOptions.chatButton?.icon})`,
		width: 60,
		height: 60,
		...botOptions.chatButtonStyle
	};
	
	return (
		<>
			{!botOptions.theme?.embedded &&
				<button
					aria-label="Open Chat"
					style={chatButtonStyle}
					className={`botai-toggle-button ${botOptions.isOpen ? "botai-button-hide" : "botai-button-show"}`}
					onClick={toggleChatWindow}
				>
					{!botOptions.notification?.disabled && botOptions.notification?.showCount &&
						<span style={botOptions.notificationBadgeStyle} className="botai-badge">
							{unreadCount}
						</span>
					}
				</button>
			}
		</>
	);
};

export default ChatBotButton;