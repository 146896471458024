
import { RefObject } from "react";

import EmojiPicker from "./EmojiPicker/EmojiPicker";
import FileAttachmentButton from "./FileAttachmentButton/FileAttachmentButton";

import "./ChatBotFooter.css";
import { useSelector } from "react-redux";

/**
 * Contains attachment button, emoji button and footer text.
 * 
 * @param inputRef reference to the textarea
 * @param flow conversation flow for the bot
 * @param textAreaDisabled boolean indicating if textarea is disabled
 * @param injectMessage utility function for injecting a message into the messages array
 * @param streamMessage utility function for streaming a message into the messages array
 * @param openChat utility function to open/close chat window
 * @param getCurrPath retrieves current path for the user
 * @param getPrevPath retrieves previous path for the user
 * @param handleActionInput handles action input from user 
 */
const ChatBotFooter = ({
	inputRef,
	flow,
	textAreaDisabled,
	injectMessage,
	streamMessage,
	openChat,
	getCurrPath,
	getPrevPath,
	handleActionInput
}) => {

	// handles options for bot
	const botOptions = useSelector((state) => state.botOptions);

	return (
		<div style={botOptions.footerStyle} className="botai-chat-footer-container">
			<div className="botai-chat-footer">
				{/* {!botOptions.fileAttachment?.disabled &&
					<FileAttachmentButton inputRef={inputRef} flow={flow} getCurrPath={getCurrPath} openChat={openChat}
						getPrevPath={getPrevPath} handleActionInput={handleActionInput} injectMessage={injectMessage}
						streamMessage={streamMessage}
					/>
				} */}
				{/* {!botOptions.emoji?.disabled &&
					<EmojiPicker inputRef={inputRef} textAreaDisabled={textAreaDisabled}/>
				} */}
			</div>
			<span>{botOptions.footer?.text}</span>
		</div>
	);
};

export default ChatBotFooter;