import { useSelector } from "react-redux";
import "./LoadingSpinner.css";

/**
 * Shows a spinning indicator when loading chat history.
 */
const LoadingSpinner = () => {

	// handles options for bot
	const botOptions = useSelector((state) => state.botOptions);

	// styles for spinner
	const loadingSpinnerStyle = {
		borderTop: `4px solid ${botOptions.theme?.primaryColor}`,
		...botOptions.loadingSpinnerStyle
	};

	return (
		<div className="botai-spinner-container">
<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
	<rect width="7.33" height="7.33" x="1" y="1" fill="#88a7d8">
		<animate id="svgSpinnersBlocksWave0" attributeName="x" begin="0;svgSpinnersBlocksWave1.end+0.3s" dur="0.9s" values="1;4;1" />
		<animate attributeName="y" begin="0;svgSpinnersBlocksWave1.end+0.3s" dur="0.9s" values="1;4;1" />
		<animate attributeName="width" begin="0;svgSpinnersBlocksWave1.end+0.3s" dur="0.9s" values="7.33;1.33;7.33" />
		<animate attributeName="height" begin="0;svgSpinnersBlocksWave1.end+0.3s" dur="0.9s" values="7.33;1.33;7.33" />
	</rect>
	<rect width="7.33" height="7.33" x="8.33" y="1" fill="#88a7d8">
		<animate attributeName="x" begin="svgSpinnersBlocksWave0.begin+0.15s" dur="0.9s" values="8.33;11.33;8.33" />
		<animate attributeName="y" begin="svgSpinnersBlocksWave0.begin+0.15s" dur="0.9s" values="1;4;1" />
		<animate attributeName="width" begin="svgSpinnersBlocksWave0.begin+0.15s" dur="0.9s" values="7.33;1.33;7.33" />
		<animate attributeName="height" begin="svgSpinnersBlocksWave0.begin+0.15s" dur="0.9s" values="7.33;1.33;7.33" />
	</rect>
	<rect width="7.33" height="7.33" x="1" y="8.33" fill="#88a7d8">
		<animate attributeName="x" begin="svgSpinnersBlocksWave0.begin+0.15s" dur="0.9s" values="1;4;1" />
		<animate attributeName="y" begin="svgSpinnersBlocksWave0.begin+0.15s" dur="0.9s" values="8.33;11.33;8.33" />
		<animate attributeName="width" begin="svgSpinnersBlocksWave0.begin+0.15s" dur="0.9s" values="7.33;1.33;7.33" />
		<animate attributeName="height" begin="svgSpinnersBlocksWave0.begin+0.15s" dur="0.9s" values="7.33;1.33;7.33" />
	</rect>
	<rect width="7.33" height="7.33" x="15.66" y="1" fill="#88a7d8">
		<animate attributeName="x" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="15.66;18.66;15.66" />
		<animate attributeName="y" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="1;4;1" />
		<animate attributeName="width" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="7.33;1.33;7.33" />
		<animate attributeName="height" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="7.33;1.33;7.33" />
	</rect>
	<rect width="7.33" height="7.33" x="8.33" y="8.33" fill="#88a7d8">
		<animate attributeName="x" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="8.33;11.33;8.33" />
		<animate attributeName="y" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="8.33;11.33;8.33" />
		<animate attributeName="width" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="7.33;1.33;7.33" />
		<animate attributeName="height" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="7.33;1.33;7.33" />
	</rect>
	<rect width="7.33" height="7.33" x="1" y="15.66" fill="#88a7d8">
		<animate attributeName="x" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="1;4;1" />
		<animate attributeName="y" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="15.66;18.66;15.66" />
		<animate attributeName="width" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="7.33;1.33;7.33" />
		<animate attributeName="height" begin="svgSpinnersBlocksWave0.begin+0.3s" dur="0.9s" values="7.33;1.33;7.33" />
	</rect>
	<rect width="7.33" height="7.33" x="15.66" y="8.33" fill="#88a7d8">
		<animate attributeName="x" begin="svgSpinnersBlocksWave0.begin+0.45s" dur="0.9s" values="15.66;18.66;15.66" />
		<animate attributeName="y" begin="svgSpinnersBlocksWave0.begin+0.45s" dur="0.9s" values="8.33;11.33;8.33" />
		<animate attributeName="width" begin="svgSpinnersBlocksWave0.begin+0.45s" dur="0.9s" values="7.33;1.33;7.33" />
		<animate attributeName="height" begin="svgSpinnersBlocksWave0.begin+0.45s" dur="0.9s" values="7.33;1.33;7.33" />
	</rect>
	<rect width="7.33" height="7.33" x="8.33" y="15.66" fill="#88a7d8">
		<animate attributeName="x" begin="svgSpinnersBlocksWave0.begin+0.45s" dur="0.9s" values="8.33;11.33;8.33" />
		<animate attributeName="y" begin="svgSpinnersBlocksWave0.begin+0.45s" dur="0.9s" values="15.66;18.66;15.66" />
		<animate attributeName="width" begin="svgSpinnersBlocksWave0.begin+0.45s" dur="0.9s" values="7.33;1.33;7.33" />
		<animate attributeName="height" begin="svgSpinnersBlocksWave0.begin+0.45s" dur="0.9s" values="7.33;1.33;7.33" />
	</rect>
	<rect width="7.33" height="7.33" x="15.66" y="15.66" fill="#88a7d8">
		<animate id="svgSpinnersBlocksWave1" attributeName="x" begin="svgSpinnersBlocksWave0.begin+0.6s" dur="0.9s" values="15.66;18.66;15.66" />
		<animate attributeName="y" begin="svgSpinnersBlocksWave0.begin+0.6s" dur="0.9s" values="15.66;18.66;15.66" />
		<animate attributeName="width" begin="svgSpinnersBlocksWave0.begin+0.6s" dur="0.9s" values="7.33;1.33;7.33" />
		<animate attributeName="height" begin="svgSpinnersBlocksWave0.begin+0.6s" dur="0.9s" values="7.33;1.33;7.33" />
	</rect>
</svg>
		</div>
	);
};

export default LoadingSpinner;
