import { useSelector } from "react-redux";

import "./ChatHistoryLineBreak.css";

/**
 * Indicates the start of old messages when viewing history.
 */
const ChatHistoryLineBreak = () => {

	// handles options for bot
	const botOptions = useSelector((state) => state.botOptions);

	return (
		<div className="botai-line-break-container">
			<div style={botOptions.chatHistoryLineBreakStyle} className="botai-line-break-text">
				{botOptions.chatHistory?.chatHistoryLineBreakText}
			</div>
		</div>
	);
};

export default ChatHistoryLineBreak;