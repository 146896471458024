import { useState, useEffect } from "react";
import ChatBotContainer from "./ChatBotContainer";
import { parseBotOptions } from "../services/BotOptionsService";
import { defaultFlow, doctorflow, isDesktop } from "../services/Utils";

const ChatBot = ({ flow, options }) => {
    const [botOptions, setBotOptions] = useState(parseBotOptions(options));
    const [paths, setPaths] = useState([]);
    const [form, setForm] = useState({});
    const [userInput, setUserInput] = useState("");
    const [currentStep, setCurrentStep] = useState("start");
    const [availabilityOptions, setAvailabilityOptions] = useState([]);
    const [selectedAvailability, setSelectedAvailability] = useState("");

    const WEBAPI_URL = process.env.REACT_APP_WEBAPI_URL;
    const ALT_API_URL = "http://127.0.0.1:8000/get-response"; // New AI endpoint

    const formStyle = {
        marginTop: 10,
        marginLeft: 20,
        border: "1px solid #491d8d",
        padding: 10,
        borderRadius: 5,
        maxWidth: 300
    };

    const newflow = {
        start: {
            message: "Hello there! What is your name?",
            function: async (params) => {
                setForm({ ...form, name: params.userInput });
                await sendApiRequestAndUpdateNextMessage("ask_age", params.userInput);
            },
            path: "ask_age"
        },
        ask_age: {
            message: (params) => `Nice to meet you ${params.userInput}, what is your age?`,
            function: async (params) => {
                setForm({ ...form, age: params.userInput });
                await sendApiRequestAndUpdateNextMessage("ask_pet", params.userInput);
            },
            path: "ask_pet"
        },
        ask_pet: {
            message: "Do you have any queries?",
            options: ["Yes", "No"],
            chatDisabled: true,
            function: async (params) => {
                setForm({ ...form, pet_ownership: params.userInput });
                await sendApiRequestAndUpdateNextMessage("ask_choice", params.userInput);
            },
            path: "ask_choice"
        },
        ask_choice: {
            message: "Select at least 2 pets that you are comfortable to work with:",
            checkboxes: { items: ["Dog", "Cat", "Rabbit", "Hamster"], min: 2 },
            chatDisabled: true,
            function: async (params) => {
                setForm({ ...form, pet_choices: params.userInput });
                await sendApiRequestAndUpdateNextMessage("ask_work_days", params.userInput);
            },
            path: "ask_work_days"
        },
        ask_work_days: {
            message: "How many days can you work per week?",
            function: async (params) => {
                setForm({ ...form, num_work_days: params.userInput });

                // Fetch data from the API
                try {
                    const response = await fetch(`${WEBAPI_URL}`);
                    const data = await response.json();
                    setForm((prevForm) => ({ ...prevForm, availability: data }));
                    setAvailabilityOptions(data);
                } catch (error) {
                    console.error("Error fetching availability data:", error);
                }

                await sendApiRequestAndUpdateNextMessage("select_time", params.userInput);
            },
            path: "select_time"
        },
        select_time: {
            message: "Please select an available time slot:",
            render: (
                <div style={formStyle}>
                    <select
                        value={selectedAvailability}
                        onChange={(e) => setSelectedAvailability(e.target.value)}
                    >
                        <option value="">Select a time slot</option>
                        {availabilityOptions.map((item) => (
                            <option key={item.id} value={item.id}>
                                {new Date(item.startTime).toLocaleString()} - {new Date(item.endTime).toLocaleString()}
                            </option>
                        ))}
                    </select>
                    <button onClick={() => handleConfirmAppointment()}>
                        Confirm Appointment
                    </button>
                </div>
            ),
            chatDisabled: true,
            path: "end"
        },
        end: {
            message: "Thank you for booking! Here are your details:",
            render: (
                <div style={formStyle}>
                    <p>Name: {form.name}</p>
                    <p>Age: {form.age}</p>
                    <p>Pet Ownership: {form.pet_ownership}</p>
                    <p>Pet Choices: {form.pet_choices}</p>
                    <p>Num Work Days: {form.num_work_days}</p>
                    <div>
                        <h4>Selected Availability:</h4>
                        {form.availability && form.availability
                            .filter(item => item.id === selectedAvailability)
                            .map(item => (
                                <div key={item.id}>
                                    <p>Start Time: {new Date(item.startTime).toLocaleString()}</p>
                                    <p>End Time: {new Date(item.endTime).toLocaleString()}</p>
                                </div>
                            ))}
                    </div>
                </div>
            ),
            options: ["New Application"],
            chatDisabled: true,
            path: "start"
        },
    };

    const [parsedFlow, setParsedFlow] = useState(flow ?? newflow);

    const sendApiRequestAndUpdateNextMessage = async (nextStep, userInput) => {
        try {
            const response = await fetch(ALT_API_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ text: userInput })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            // Append the content of the next question in the flow
            if (parsedFlow[nextStep]) {
                setParsedFlow(prevFlow => {
                    const updatedFlow = { ...prevFlow };
                    updatedFlow[nextStep].message += ` ${data.response}`;
                    return updatedFlow;
                });
            }
            console.log(parsedFlow)
        } catch (error) {
            console.error("Error fetching AI response:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const step = parsedFlow[currentStep];
        if (step.function) {
            await step.function({ userInput }, setForm, form);
        }
        setCurrentStep(step.path);
        setUserInput("");
    };

    const handleOptionClick = (option) => {
        setUserInput(option);
        handleSubmit(new Event('submit'));
    };

    const handleConfirmAppointment = () => {
        if (selectedAvailability) {
            setForm((prevForm) => ({
                ...prevForm,
                selectedAvailability: selectedAvailability,
            }));
            setCurrentStep("end");
        }
    };

    const step = parsedFlow[currentStep];

    const renderChatBot = () => {
        let result = <ChatBotContainer flow={parsedFlow} />;
        return result;
    };

    const shouldShowChatBot = () => {
        return (isDesktop && botOptions.theme?.desktopEnabled)
            || (!isDesktop && botOptions.theme?.mobileEnabled);
    };

    return (
        <>
            {shouldShowChatBot() &&
                <div style={{ fontFamily: botOptions.theme?.fontFamily }}>
                    {renderChatBot()}
                </div>
            }
        </>
    );
};

export default ChatBot;
