import { Dispatch } from "react";
import { addPath } from "../../redux/slices/pathsSlice";

/**
 * Handles processing of path in current block.
 * 
 * @param block current block being processed
 * @param params contains userInput, prevPath and injectMessage that can be used/passed into attributes
 * @param dispatch function to dispatch actions to the Redux store
 */
export const processPath = async (block, params, setPaths) => {
	const nextPath = block.path;
	if (!nextPath) {
		return false;
	}

	if (typeof nextPath === "string") {
		setPaths(prev => [...prev, nextPath]);
		return true;
	}

	let parsedPath = nextPath(params);
	if (parsedPath instanceof Promise) {
		parsedPath = await parsedPath;
	}

	if (!parsedPath) {
		return false;
	}
	const path = parsedPath;
	setPaths(prev => [...prev, path]);
	return true;
}
