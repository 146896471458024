import { createElement, isValidElement, Dispatch, SetStateAction, ReactNode, CSSProperties } from "react";
import ReactDOMServer from "react-dom/server";

import ChatHistoryLineBreak from "../components/ChatHistoryLineBreak/ChatHistoryLineBreak";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
// variables used to track history, updated when botOptions.chatHistory value changes
let historyLoaded = false;
let historyStorageKey = "botai-history";
let historyMaxEntries = 30;
let historyDisabled = false;
let historyMessages = [];


const saveChatHistory = async (messages = []) => {
	if (historyDisabled) {
		return;
	}
	
	const messagesToSave = [];
	const offset = historyLoaded ? historyMessages.length : 0;

	for (let i = messages.length - 1; i >= offset; i--) {
		const message = messages[i];
		
		if (!message) {
			continue;
		}

		if (message.sender === "system") {
			break;
		}

		if (message.content !== "") {
			messagesToSave.unshift(message);
		}

		if (messagesToSave.length === historyMaxEntries) {
			break;
		}
	}

	let parsedMessages = messagesToSave.map(parseMessageToString);
	if (parsedMessages.length < historyMaxEntries) {
		const difference = historyMaxEntries - parsedMessages.length;
		parsedMessages = [...historyMessages.slice(-difference), ...parsedMessages];
	}

	localStorage.setItem(historyStorageKey, JSON.stringify(parsedMessages));
}

/**
 * Retrieves chat history.
 * 
 * @param historyStorageKey key used to identify chat history stored in local storage
 */
const getHistoryMessages = (chatHistory) => {
	if (chatHistory != null) {
		try {
			return JSON.parse(chatHistory);
		} catch {
			return [];
		}
	}
	return [];
}

const setHistoryStorageValues = (botOptions) => {
	historyStorageKey = botOptions.chatHistory?.storageKey;
	historyMaxEntries = botOptions.chatHistory?.maxEntries;
	historyDisabled = botOptions.chatHistory?.disabled;
	historyMessages = getHistoryMessages(localStorage.getItem(historyStorageKey));
}

const parseMessageToString = (message) => {
	if (isValidElement(message.content)) {
		const clonedMessage = structuredClone({
			content: ReactDOMServer.renderToString(message.content),
			type: "object",
			sender: message.sender,
		});
		return clonedMessage;
	}

	return {...message, type: "string"}
}

const loadChatHistory = (botOptions, chatHistory, setMessages, setTextAreaDisabled) => {
	historyLoaded = true;
	if (chatHistory != null) {
		try {
			setMessages((prevMessages = []) => {
				const loaderMessage = {
					content: <LoadingSpinner />,
					sender: "system",
				};
				prevMessages.shift();
				return [loaderMessage, ...prevMessages];
			});

			const parsedMessages = JSON.parse(chatHistory).map((message) => {
				if (message.type === "object") {
					const element = renderHTML(message.content, botOptions);
					return { ...message, content: element };
				}
				return message;
			});

			setTimeout(() => {
				setMessages((prevMessages = []) => {
					prevMessages.shift();
					// if autoload, line break is invisible
					let lineBreakMessage;
					if (botOptions.chatHistory?.autoLoad) {
						lineBreakMessage = {
							content: <></>,
							sender: "system",
						};
					} else {
						lineBreakMessage = {
							content: <ChatHistoryLineBreak />,
							sender: "system",
						};
					}
					return [...parsedMessages, lineBreakMessage, ...prevMessages];
				});
				setTextAreaDisabled(botOptions.chatInput?.disabled || false);
			}, 500);
		} catch {
			// remove chat history on error (to address corrupted storage values)
			localStorage.removeItem(botOptions.chatHistory?.storageKey);
		}
	}
}


/**
 * Renders html string to a react node.
 * 
 * @param html string to render
 * @param botOptions options provided to the bot
 */
const renderHTML = (html, botOptions) => {
	const parser = new DOMParser();
	const parsedHtml = parser.parseFromString(html, "text/html");
	const nodes = Array.from(parsedHtml.body.childNodes);
  
	const renderNodes = nodes.map((node, index) => {
		if (node.nodeType === Node.TEXT_NODE) {
			return node.textContent;
		} else {
			const tagName = (node).tagName.toLowerCase();
			let attributes = Array.from((node).attributes).reduce((acc, attr) => {
				const attributeName = attr.name.toLowerCase();
				if (attributeName === "style") {
					const styleProperties = attr.value.split(";").filter(property => property.trim() !== "");
					const styleObject = {};
					styleProperties.forEach(property => {
						const [key, value] = property.split(":").map(part => part.trim());
						const reactCompliantKey = key.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
						styleObject[reactCompliantKey] = value;
					});
					acc[attributeName] = styleObject;
				} else {
					acc[attributeName] = attr.value;
				}
				return acc;
			}, {});

			const classList = (node).classList;
			if (botOptions.botBubble?.showAvatar) {
				attributes = addStyleToContainers(classList, attributes);
			}
			attributes = addStyleToOptions(classList, attributes, botOptions);
			attributes = addStyleToCheckboxRows(classList, attributes, botOptions);
			attributes = addStyleToCheckboxNextButton(classList, attributes, botOptions);
			const children = renderHTML((node ).innerHTML, botOptions);
			return createElement(tagName, { key: index, ...attributes }, children);
		}
	});
  
	return renderNodes;
};


const addStyleToContainers = (classList, attributes) => {
	if (classList.contains("botai-options-container") || classList.contains("botai-checkbox-container")) {
		if (Object.prototype.hasOwnProperty.call(attributes, "class")) {
			attributes["class"] = `${classList.toString()} botai-options-offset`;
		} else {
			attributes["class"] = "botai-options-offset"
		}
	}
	return attributes;
}


const addStyleToOptions = (classList, attributes,
	botOptions) => {
	if (classList.contains("botai-options")) {
		attributes["style"] = {
			...(attributes["style"]),
			color: botOptions.botOptionStyle?.color || botOptions.theme?.primaryColor,
			borderColor: botOptions.botOptionStyle?.color || botOptions.theme?.primaryColor,
			cursor: `url(${botOptions.theme?.actionDisabledIcon}), auto`,
			...botOptions.botOptionStyle
		}
	}
	return attributes;
}


const addStyleToCheckboxRows = (classList, attributes,
	botOptions) => {
	if (classList.contains("botai-checkbox-row-container")) {
		attributes["style"] = {
			...(attributes["style"]),
			color: botOptions.botCheckboxRowStyle?.color || botOptions.theme?.primaryColor,
			borderColor: botOptions.botCheckboxRowStyle?.color || botOptions.theme?.primaryColor,
			cursor: `url(${botOptions.theme?.actionDisabledIcon}), auto`,
			...botOptions.botCheckboxRowStyle
		}
	}
	return attributes;
}

const addStyleToCheckboxNextButton = (classList, attributes,
	botOptions) => {
	if (classList.contains("botai-checkbox-next-button")) {
		attributes["style"] = {
			...(attributes["style"]),
			color: botOptions.botCheckboxNextStyle?.color || botOptions.theme?.primaryColor,
			borderColor: botOptions.botCheckboxNextStyle?.color || botOptions.theme?.primaryColor,
			cursor: `url(${botOptions.theme?.actionDisabledIcon}), auto`,
			...botOptions.botCheckboxNextStyle
		}
	}
	return attributes;
}

export {
	saveChatHistory,
	loadChatHistory,
	setHistoryStorageValues
}