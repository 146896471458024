import React, { useEffect, useState } from "react";
import ChatBot from "./components/ChatBot";

function App() {
  const [fakeData, setFakeData] = useState({});

  // Function to fetch data from JSONPlaceholder API
  const fetchFakeData = async () => {
    try {
      const userResponse = await fetch('https://jsonplaceholder.typicode.com/users/1');
      const user = await userResponse.json();
      const emailResponse = await fetch('https://jsonplaceholder.typicode.com/comments?postId=1');
      const emailData = await emailResponse.json();
      const phoneResponse = await fetch('https://jsonplaceholder.typicode.com/users/1');
      const phone = await phoneResponse.json();
      
      const appointmentDate = new Date().toLocaleDateString();
      const symptoms = "Lorem ipsum dolor sit amet";
      const severity = Math.floor(Math.random() * 10) + 1;

      setFakeData({
        name: user.name,
        email: emailData[0].email,
        phone: phone.phone,
        appointmentDate,
        symptoms,
        severity
      });
    } catch (error) {
      console.error("Error fetching fake data:", error);
    }
  };

  useEffect(() => {
    fetchFakeData();
  }, []);

  const flow = {
    start: {
      message: "Hello! What is your name?",
      path: "ask_age",
    },
    ask_age: {
      message: (params) => `Nice to meet you, ${params.userInput}! How old are you?`,
      path: "ask_health_problem",
    },
    ask_health_problem: {
      message: "What health issue are you experiencing?",
      options: ["General Checkup", "Cold/Flu", "Headache", "Stomach Ache", "Other"],
      chatDisabled: true,
      path: (params) => (params.userInput === "Other" ? "ask_custom_health_problem" : "ask_symptoms"),
    },
    ask_custom_health_problem: {
      message: "Please describe your health issue.",
      path: "ask_symptoms",
    },
    ask_symptoms: {
      message: "Could you describe your symptoms?",
      path: "ask_duration",
    },
    ask_duration: {
      message: "How long have you been experiencing these symptoms?",
      path: "ask_severity",
    },
    ask_severity: {
      message: "How severe are your symptoms on a scale of 1-10?",
      path: "ask_contact_details",
    },
    ask_contact_details: {
      message: "Please provide your contact details.",
      form: {
        fields: [
          { label: "Email", type: "email" },
          { label: "Phone Number", type: "text" },
        ],
      },
      path: "ask_appointment_time",
    },
    ask_appointment_time: {
      message: "When would you like to book your appointment?",
      options: ["Today", "Tomorrow", "Later This Week"],
      chatDisabled: true,
      path: "confirm_appointment",
    },
    confirm_appointment: {
      message: (params) => {
        return (
          <div>
            <p>Thank you! You have booked an appointment for {params.userInput}. Here are the details:</p>
            <ul>
              {/* <li>Name: {fakeData.name}</li>
              <li>Email: {fakeData.email}</li>
              <li>Phone: {fakeData.phone}</li>
              <li>Appointment Date: {fakeData.appointmentDate}</li>
              <li>Symptoms: {fakeData.symptoms}</li>
              <li>Severity: {fakeData.severity}</li> */}
            </ul>
          </div>
        );
      },
      path: "ask_feedback",
    },
    ask_feedback: {
      message: "How would you rate your experience with our chatbot?",
      options: ["1", "2", "3", "4", "5"],
      chatDisabled: true,
      path: "ask_image",
    },
	ask_image: {
		message: (params) => `${params.userInput}? Interesting. Could you share an image of that?`,
		file: (params) => console.log(params.files),
		path: "end"
	},
    end: {
      message: "Thank you for your feedback! Have a great day!",
      path: "loop",
    },
    loop: {
      message: "You have reached the end of the conversation!",
      path: "loop",
    },
  };

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: `calc(20vh)` }}>
          <ChatBot
            flow={flow}
            options={{
              audio: { disabled: false },
              chatInput: { botDelay: 1000 },
              userBubble: { showAvatar: true },
              botBubble: { showAvatar: true },
              voice: { disabled: false }
            }}
          />
        </div>
      </header>
    </div>
  );
}

export default App;
